import { Avatar } from "components/ui";
import { components } from "react-select";
const { Control } = components;

const SelectControlWithLogo = ({ children, ...props }) => {
  const selected = props.getValue()[0];
  return (
    <Control {...props}>
      {selected && (
        <Avatar
          className="ltr:ml-4 rtl:mr-4"
          shape="circle"
          size={18}
          src={selected.imgPath}
        />
      )}
      {children}
    </Control>
  );
};

export default SelectControlWithLogo;
