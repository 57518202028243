import axios from "axios";
import appConfig from "configs/app.config";
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";
import { Notification, toast } from "components/ui";
import queryString from "query-string";

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);

    const accessToken = persistData.auth.session.token;

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response.status === 422) {
      let payload = {
        // responseUrl: response.request.responseURL,
        // data: response.config.data,
        serverMessage: response.data,
      };
      window.location.href = `/access-denied?${queryString.stringify(payload)}`;
    } else if (response.status === 500) {
      let payload = {
        responseUrl: response.request.responseURL,
        data: response.config.data,
        serverMessage: response.data?.Detail,
      };
      window.location.href = `/error?${queryString.stringify(payload)}`;
    } else if (response.data?.errors) {
      toast.push(
        <Notification
          title={response.data?.title}
          type="danger"
          duration={3500}
          width={500}
        >
          <div className="grid gap-2.5">
            {Object.values(response.data?.errors).map((item, i) => (
              <span>{item[0]}</span>
            ))}
          </div>
        </Notification>,
        {
          placement: "top-center",
        }
      );
    } else {
      toast.push(
        <Notification title={"İşlem Başarısız!"} type="danger" duration={3500}>
          {response.data}
        </Notification>,
        {
          placement: "top-center",
        }
      );
    }
    if (response && unauthorizedCode.includes(response.status)) {
      store.dispatch(onSignOutSuccess());
      window.location.href = `/sign-in`;
    }
    // return Promise.reject(error);
  }
);

export default BaseService;
