const appConfig = {
  apiPrefix: process.env.REACT_APP_API_KEY,
  gtmCode: process.env.REACT_APP_GA_KEY,
  env: process.env.REACT_APP_ENV,
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/welcome",
  tourPath: "/",
  enableMock: false,
};

export default appConfig;
