import { THEME_ENUM } from "constants/theme.constant";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
  themeColor: "lime",
  direction: THEME_ENUM.DIR_LTR,
  mode: THEME_ENUM.MODE_LIGHT,
  locale: localStorage.getItem("locale") || "tr",
  primaryColorLevel: 500,
  cardBordered: true,
  panelExpand: false,
  controlSize: "md",
  navMode: THEME_ENUM.NAV_MODE_LIGHT,
  layout: {
    type: THEME_ENUM.LAYOUT_TYPE_CLASSIC,
    sideNavCollapse: false,
  },
};
