import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: `User/Login?email=${data.email}&password=${data.password}`,
    method: "post",
  });
}
export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "User/Register?",
    method: "post",
    data,
  });
}
export async function apiGetForgotPassword(data) {
  return ApiService.fetchData({
    url: `User/ForgotPassword?email=${data}`,
    method: "get",
  });
}
export async function apiPostForgotPassword(data) {
  return ApiService.fetchData({
    url: "User/ForgotPassword",
    method: "post",
    data,
  });
}
